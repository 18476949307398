import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { checkUserToken } from '@/api/config';

import VueI18n from '@/i18n';

Vue.use(VueRouter);
Vue.use(VueI18n);

const routes = [
  {
    path: '/',
    component: () => import('@/components/Main/Main.vue'),
    meta: {
      title: `${VueI18n.t('titles.main')}`,
    },
    children: [
      {
        path: '/sign-in',
        name: 'auth',
        component: () => import('@/components/Auth/Auth.vue'),
        meta: {
          title: `${VueI18n.t('titles.auth')}`,
        },
        beforeEnter: (to, from, next) => {
          if (to.name === 'auth' && checkUserToken()) {
            next({ name: 'dashboard' });
          } else {
            next();
          }
        },
      },
      {
        path: '/',
        name: 'dashboard',
        component: () => import('@/components/Dashboard/Dashboard.vue'),
        meta: {
          title: `${VueI18n.t('titles.dashboard')}`,
        },
      },
      {
        path: '/:notFound(.*)*',
        name: 'notFound',
        component: () => import('@/components/NotFound/NotFound.vue'),
        meta: {
          title: `${VueI18n.t('titles.not_found')}`,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.name !== 'auth' && !checkUserToken()) {
    store.commit('LOGOUT_USER');
    next({ name: 'auth' });
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title =
      to.meta && to.meta.title
        ? `${to.meta.title} | ${process.env.VUE_APP_TITLE}`
        : process.env.VUE_APP_TITLE;
  });
});

export default router;
