import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';
import {
  login,
  logout,
  barrierBroadcast,
  approveTicketAsPresentInCustomsZone,
  approveTurnTicketToHome,
  customsControlBroadcast,
  approveTicketCustomsControl,
  declineTicketCustomsControl,
  takeInWorkTicketCustomsControl,
} from '@/api/services';
import { setToLS, getFromLS, isLSHasItem } from '@/library/helpers';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    operator: isLSHasItem('operator') ? getFromLS('operator') : null,
    token: isLSHasItem('token') ? getFromLS('token') : null,
    notification: null,
  },

  getters: {
    isAuthUser: (state) => !!state.token,
    getUser: (state) => state.operator,
    getNotification: (state) => state.notification,
  },

  mutations: {
    SAVE_USER(state, payload) {
      const { token, operator } = payload;
      state.operator = operator;
      state.token = token;
      setToLS('token', token);
      setToLS('operator', operator);
    },

    LOGOUT_USER(state) {
      state.operator = null;
      state.token = null;
      localStorage.clear();
    },
    setNotification(state, notification) {
      state.notification = notification;
    },
    clearNotification(state) {
      state.notification = null;
    },
  },

  actions: {
    async authorization({ commit }, data) {
      let res;

      try {
        res = await login(data);
        const { access_token: token, operator_customs_zone: operator } =
          res.data.data;

        commit('SAVE_USER', {
          token,
          operator,
        });

        router.push({ name: 'dashboard' });
      } catch (error) {
        const statusCode = error.response ? error.response.status : null;

        if (statusCode === 401) {
          return error.response.data.status;
        }

        error.handleGlobally && error.handleGlobally();
      }
    },

    async userLogout({ commit }) {
      try {
        await logout();
        commit('LOGOUT_USER');
        router.push({ name: 'auth' });
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    // Barrier role

    async getBarrierBroadcast() {
      try {
        const res = await barrierBroadcast();
        return res.data.data;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    async approveTicketAsPresentInCustomsZone(_, uuid) {
      try {
        await approveTicketAsPresentInCustomsZone(uuid);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    async approveTurnTicketToHome(_, uuid) {
      try {
        await approveTurnTicketToHome(uuid);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    // Customs control role

    async getCustomsControlBroadcast() {
      try {
        const res = await customsControlBroadcast();
        return res.data.data;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    async takeInWorkTicketCustomsControl(_, uuid) {
      try {
        await takeInWorkTicketCustomsControl(uuid);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    async declineTicketCustomsControl(_, uuid) {
      try {
        await declineTicketCustomsControl(uuid);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    async approveTicketCustomsControl(_, uuid) {
      try {
        await approveTicketCustomsControl(uuid);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
  },
  modules: {},
});
