import { Axios } from '@/api/config';

const api_version = '/api/v1.0';

export const login = (data) => {
  const url = `${api_version}/customs-zone/operator/auth/sign-in`;
  return Axios.post(url, data);
};

export const logout = () => {
  const url = `${api_version}/customs-zone/operator/auth/logout`;
  return Axios.post(url);
};

export const barrierBroadcast = () => {
  const url = `${api_version}/customs-zone/operator/barrier/broadcast`;
  return Axios.get(url);
};

export const approveTicketAsPresentInCustomsZone = (uuid) => {
  const url = `${api_version}/customs-zone/operator/barrier/approve-ticket-as-present-in-customs-zone?uuid=${uuid}`;
  return Axios.post(url);
};

export const approveTurnTicketToHome = (uuid) => {
  const url = `${api_version}/customs-zone/operator/barrier/ticket-turn-to-home?uuid=${uuid}`;
  return Axios.post(url);
};

export const customsControlBroadcast = () => {
  const url = `${api_version}/customs-zone/operator/customs-control/broadcast`;
  return Axios.get(url);
};

export const takeInWorkTicketCustomsControl = (uuid) => {
  const url = `${api_version}/customs-zone/operator/customs-control/take-in-work?uuid=${uuid}`;
  return Axios.post(url);
};

export const declineTicketCustomsControl = (uuid) => {
  const url = `${api_version}/customs-zone/operator/customs-control/decline?uuid=${uuid}`;
  return Axios.post(url);
};

export const approveTicketCustomsControl = (uuid) => {
  const url = `${api_version}/customs-zone/operator/customs-control/approve?uuid=${uuid}`;
  return Axios.post(url);
};
